$primary20: #135c9d;

$blue10: #f3f3fb;
$blue20: #237BC9;
$blue50: #0088ff;
$blue90: #06122f;

$gray30: #f7f7f7;
$gray40: #e5e5e5;
$gray50: #cccccc;
$gray60: #7f7f7f;
$gray70: #444444;