@import "../styles/valuable.scss";

button {
  border-radius: 100rem;
  display: flex;
  align-items: center;
  gap: 4rem;
  cursor: pointer;
  font-size: 16rem;
  font-weight: 800;
}

button.rg {
  padding: 16rem 24rem;
}

button.md {
  padding: 8rem 16rem;
}

.button {
  &-ghost {
    background: none;
    border: 1rem solid white;
    font-weight: 700;
  }
  &-light {
    background-color: white;
    color: $blue50;
  }
  &-gray {
    background-color: $blue10;
    color: $blue50;
  }
}

.memberCard {
  background-color: rgba(0, 0, 0, 0.03);
  width: 388em;
  padding: 24em 28em;
  border-radius: 16em;
  img {
    width: 64em;
    height: 64em;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
  }
  p {
    font-size: 18em;
    font-weight: 700;
    margin-bottom: 0;
  }
  .name {
    font-size: 26em;
  }
  > div {
    display: flex;
    gap: 8em;
    align-items: center;
    margin: 24em 0 8em 0;
  }
}
.memberCard.dark {
  background-color: #1c2845;
  .name {
    color: white;
    ~ p {
      color: #2ecc71;
    }
  }
  p {
    color: $gray50;
  }
}
.memberCard.light {
  background-color: #f7f7f7;
  width: 100%;
  padding: 32em;
  .name {
    color: black;
    ~ p {
      color: $blue20;
    }
  }
  p {
    color: $gray70;
  }
}

header {
  width: 100vw;
  height: 60em;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 160rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 42em;
  }
  nav {
    font-size: 16em;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    a {
      padding: 0 1em;
      color: $gray50;
    }
  }
  .navi-blue {
    a {
      color: white;
    }
  }
  // @todo 디자인 나오면 적용하기
  // .menu {
  //   display: none;
  //   align-items: center;
  //   justify-content: center;
  //   width: 32px;
  //   height: 32px;
  //   border-radius: 0;
  //   background: none;
  //   cursor: pointer;
  //   > img {
  //     width: 24px;
  //   }
  // }
}

footer {
  width: 100vw;
  height: 212rem;
  padding: 64rem 160rem;
  background-color: $gray30;
  h6 {
    color: black;
    font-size: 24rem;
    margin-top: 16rem;
  }
}

@media (max-width: 1024px) {
  header {
    font-size: 0.6px;
  }
}

@media (max-width: 768px) {
  header {
    font-size: 0.8px;
  }
}

@media (max-width: 426px) {
  footer {
    padding: 64rem 24px;
  }
  button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  header {
    padding: 0 24px;
    nav {
      display: none;
    }
  }
}
