@import "../../styles/valuable.scss";

#member {
  padding: 160rem;
  .headline {
    h2,
    span {
      font-size: 48rem;
    }
    h2 {
      color: black;
    }
  }

  .listWrapper {
    margin-top: 96px;
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  section {
    h2 {
      font-size: 36rem;
      color: $primary20;
    }
    .listGrid {
      width: 100%;
      margin-top: 16px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
    }
  }
}

@media (max-width: 1024px) {
  #member {
    section {
      .listGrid {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
@media (max-width: 768px) {
  #member {
    section {
      .listGrid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
@media (max-width: 426px) {
  #member {
    padding: 160rem 24px;
    .listWrapper {
      margin-top: 60px;
    }
    section {
      .listGrid {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
