@import "../../../styles/valuable.scss";

@media (max-width: 426px) {
  .team,
  .member {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 48rem;
  }
}

.team,
.member {
  background-color: $blue90;
  display: flex;
  align-items: center;
  > div {
    width: 100%;
  }
  h2 {
    font-size: 64rem;
    margin-bottom: 16px;
  }
  .introduction {
    line-height: 170%;
    color: $gray60;
    word-break: keep-all;
    white-space: pre-wrap;
    margin-bottom: 48px;
  }
  > .logo {
    width: 100%;
    aspect-ratio: 1 / 1;
  }
}

.highlight {
  font-size: 64rem;
  color: $blue50;
}
