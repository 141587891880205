@import "../../../styles/valuable.scss";

.intro {
  background-color: $blue50;
  display: flex;
  justify-content: center;
  align-items: center;
  h1,
  h2 {
    font-size: 96rem;
    text-align: center;
    word-break: keep-all;
  }
  &-keyword {
    height: 144rem;
    overflow: hidden;
  }
}

.scroll-hint {
  position: absolute;
  bottom: 100rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  p {
    font-size: 16em;
  }
  img {
    width: 24em;
    height: 24em;
  }
}

.gradient {
  width: 100vw;
  height: 900rem;
  background: linear-gradient(0deg, #06122f 0%, #08f 100%);
}

@media (max-width: 426px) {
  .scroll-hint {
    font-size: 0.8px;
    img {
      width: 30em;
      height: 30em;
    }
  }
}
