@import "../../../styles/valuable.scss";

.jobis {
  height: 100vw;
  display: flex;
  flex-direction: column;
  gap: 48rem;
  padding-top: 160rem;
  padding-bottom: 160rem;
  section {
    max-width: 626rem;
    word-break: keep-all;
    h2 {
      color: #000;
      font-size: 64rem;
    }
    p {
      color: $gray60;
      margin: 16rem 0 48rem 0;
    }
  }
  .macbook {
    flex: 1;
    img {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .jobis {
    height: 100vh;
  }
}
